.modal-box{
    .confirm-box{
        width: 90%;
        max-width: 415px;
        margin: 30px auto;
        transition: all 0.2s ease-in-out;
        border-radius: 10px;
        transform: scale(0.9);
        text-align: left;
        > div{
            &:first-of-type{
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
            }
            &:last-of-type{
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
            }
            & + div{
                margin-top: -1px;
            }
        }
        .confirm-box-header{

        }
        .confirm-box-body{
            font-size: 1rem;
            letter-spacing: 1px;
            padding: 15px;
            color: #444;
            background-color: #fff;
        }
        .confirm-box-footer{
            text-align: right;
            padding: 15px;
            background-color: #fff;
            .confirm-btn{
                -webkit-appearance: none;
                background-color: transparent;
                appearance: none;
                padding: 6px 16px;
                border-radius: 3px;
                border: 0;
                margin-left: 8px;
                font-size: 14px;
                color: #1a73e8;
                &:hover{
                    color: #174ea6;
                    background-color: #F6F9FE;
                }
                &.cancel{
                    // background-color: transparent;
                }
                &.confirm{
                    // background-color: #555;
                    // color: #fff;
                }
            }
        }
    }
}

.confirm-transition-enter{
    visibility: hidden !important;
    background-color: rgba(0,0,0,0) !important;
    .confirm-box{
        transform: scale(0.9) !important;
        opacity: 0 !important;
    }
}
.confirm-transition-enter-active{
    visibility: visible !important;
    background-color: rgba(0,0,0,0.55) !important;
    transition: all 300ms ease-in-out !important;
    .confirm-box{
        transform: scale(1) !important;
        opacity: 1 !important;
        transition: all 200ms ease-in-out !important;
    }
}
.confirm-transition-enter-done{
    visibility: visible !important;
    background-color: rgba(0,0,0,0.55) !important;
    .confirm-box{
        transform: scale(1) !important;
        opacity: 1 !important;
    }
}

.confirm-transition-exit{
    visibility: visible !important;
    background-color: rgba(0,0,0,0.55) !important;
    .confirm-box{
        transform: scale(1) !important;
        opacity: 1 !important;
    }
}
.confirm-transition-exit-active{
    visibility: hidden !important;
    background-color: rgba(0,0,0,0) !important;
    transition: all 300ms ease-in-out !important;
    .confirm-box{
        transform: scale(0.9) !important;
        opacity: 0 !important;
        transition: all 200ms ease-in-out !important;
    }
}
.confirm-transition-exit-done{
    visibility: hidden !important;
    background-color: rgba(0,0,0,0) !important;
    .confirm-box{
        transform: scale(0.9) !important;
        opacity: 0 !important;
    }
}