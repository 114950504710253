.common-form{
    max-width: 1040px;
    margin: 0 auto;
    &.form-center {
        margin: 0 auto;
    }
    & + .common-form{
        margin: 30px auto 0 auto;
    }
    .form-container{
        box-shadow: 0 0 10px rgba(0,0,0,0.1);
        border-radius: 10px;
        margin-bottom: 15px;
    }
}

.form-group{
    border-bottom: 1px solid rgba(0,0,0,0.05);
    background-color: #fff;
    padding: 15px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-bottom: 0;
    transition: all 0.1s ease-in-out;
    &:hover{
        background-color: #f9f9f9;
        .input-group{
            .input-desc{
                color: #969696;
            }
        }
    }
    &:first-child{
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
    }
    &:last-child{
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
    }
    .label-group{
        width: 120px;
        margin-right: 10px;
        @media screen and (max-width: 768px) {
            width: 100%;
            display: flex;
            align-items: center;
        }
        label{
            // min-width: 120px;
            font-size: 14px;
            text-align: left;
            margin-right: 10px;
            height: 42px;
            display: flex;
            align-items: center;
            // padding: 7px 0;
            // font-weight: 400;
            font-family: 微軟正黑體;
        }
        i{
            color: #aaa;
            cursor: help;
        }
    }
    .text-group {
        padding-top: 7px;
        min-height: 42px;
    }
    .switch-group {
        height: 42px;
        display: flex;
        align-items: center;
    }
    .input-group {
        // display: unset;
        flex: 1;
        border: 1px solid #e3e3e3;
        border-radius: 5px;
        align-items: center;
        min-height: 40px;
        // white-space: nowrap;
        flex-wrap: nowrap;
        overflow: hidden;
        background-color: #fff;
        &:focus {
            border: 1px solid #d1d1d1;
        }
        &.tiny{
            max-width: 100px;
        }
        &.readOnly {
            border: 0;
        }
        .switch-container{
            width: 100%;
            .switch-box{
                display: flex;
                align-items: center;
                margin-top: 10px;
                &:first-child{
                    margin-top: 0;
                }
            }
        }
        .input-desc{
            color: #ccc;
            font-size: 0.85rem;
            margin-top: 10px;
            white-space: normal;
            width: 100%;
            transition: all 0.15s ease-in-out;
            @media screen and (max-width: 768px){
                color: #969696;
            }
        }
        .input-text{
            // font-weight: bold;
        }
    }
    .radio-box{
        display: flex;
        align-items: center;
        margin-top: 10px;
        &:first-child{
            margin-top: 0;
        }
        > label{
            font-weight: normal;
            cursor: pointer;
            // padding-left: 10px;
        }
    }
    input, select{
        -webkit-appearance: none;
        appearance: none;
        // flex: 1;
        border: 0;
        width: 100%;
        background-color: #fff;
        padding: 0 10px;
        font-size: 16px;
        box-shadow: none;
        &:focus{
            outline: none;
            box-shadow: none;
        }
    }
    input[type="file"]{
        border: 0;
        padding: 0;
        border-radius: 0;
        background-color: unset;
        &:read-only{
            background-color: transparent;
        }
    }
    input{
        height: 40px;
        letter-spacing: 1px;
        &::placeholder{
            color: #aaa;
            font-weight: normal;
        }
        &:read-only{
            border: 0;
            // border-radius: 0;
            // background-color: transparent;
            // padding: 7px 0;
            // border-bottom: 1px solid #d1d1d1;
            // background-color: rgba(0,0,0,0.03);
            border-radius: 5px;
            padding: 7px 10px;
        }
    }
    // input[type="checkbox"],
    // input[type="radio"]{
    //     cursor: pointer;
    //     min-width: 20px;
    //     max-width: 20px;
    //     width: 20px;
    //     min-height: 20px;
    //     max-height: 20px;
    //     height: 20px;
    //     -webkit-appearance: none;
    //     appearance: none;
    //     border: 1px solid $text-secondary;
    //     border-radius: 0;
    //     position: relative;
    //     padding: 0;
    //     background-color: #fff;
    //     &:checked{
    //         &:before{
    //             content: '\f00c';
    //             font-family: 'Font Awesome 5 Pro';
    //             font-size: 14px;
    //             width: 100%;
    //             height: 100%;
    //             display: flex;
    //             justify-content: center;
    //             align-items: center;
    //             background-color: #D70C18;
    //             color: #fff;
    //             animation: zoomIn 0.15s ease-in-out 1;
    //             @keyframes zoomIn {
    //                 0%{
    //                     transform: scale(0);
    //                 }
    //                 100%{
    //                     transform: scale(1);
    //                 }
    //             }
    //         }
    //         border: 0;
    //     }
    //     &:focus{
    //         outline: none;
    //     }
    // }
    select{
        border: 1px solid #e3e3e3;
        border-radius: 5px;
        flex: 1;
        height: 42px;
        cursor: pointer;
        background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
        &:focus {
            border: 1px solid #d1d1d1;
        }
        optgroup{
            font-family: 微軟正黑體;
            color: #555;
        }
    }
    textarea{
        -webkit-appearance: none;
        appearance: none;
        border: 0;
        width: 100%;
        padding: 7px 10px;
        font-size: 16px;
        background-color: #fff;
        &::placeholder{
            color: #aaa;
            font-weight: normal;
        }
    }
}


// select{
//     border: 1px solid #e3e3e3;
//     border-radius: 5px;
//     flex: 1;
//     height: 42px;
//     cursor: pointer;
//     background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
//     // @media screen and (max-width: 768px) {
//     //     margin-top: 15px;
//     // }
//     &:focus {
//         border: 1px solid #d1d1d1;
//     }
//     optgroup{
//         font-family: 微軟正黑體;
//         color: #555;
//     }
//     &.tiny{
//         max-width: 130px;
//     }
// }

.submit-btn{
    border: 0;
    background-color: #1976d2;
    color: $text-dark;
    color: #fff;
    padding: 10px 15px;
    border-radius: 5px;
    transition: all 0.1s ease-in-out;
    box-shadow: 1px 2px 3px rgba(0,0,0,0.2);
    &:hover{
        background-color: #166ec5;
    }
}

.view-pass-btn{
    background-color: transparent;
    border: 0;
}

// input[type="checkbox"],
// input[type="radio"]{
//     cursor: pointer;
//     min-width: 20px;
//     max-width: 20px;
//     width: 20px;
//     min-height: 20px;
//     max-height: 20px;
//     height: 20px;
//     -webkit-appearance: none;
//     appearance: none;
//     border: 1px solid $text-secondary;
//     border-radius: 3px;
//     position: relative;
//     padding: 0;
//     &:checked{
//         &:before{
//             content: '\f00c';
//             font-family: 'Font Awesome 5 Pro';
//             font-size: 14px;
//             position: absolute;
//             top: 50%;
//             left: 50%;
//             transform: translate(-50%,-50%);
//             color: #fff;
//         }
//         background-color: #D70C18;
//         border: 1px solid transparent;
//     }
//     &:focus{
//         outline: none;
//     }
// }

.MuiCheckbox-colorPrimary.Mui-checked{
    color: #1976d2 !important;
}

.search-group{
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% + 20px);
    margin: -10px;
    padding: 10px;
    z-index: 1;
    &.active{
        box-shadow: 0 0 10px rgba(0,0,0,0.1);
        border-radius: 10px;
        background-color: #fff;
    }
    ul.search-list{
        width: 100%;
        background-color: #fff;
        max-height: 200px;
        overflow-y: auto;
        // margin-top: 10px;
        position: relative;
        li.search-item{
            & + li.search-item{
                margin-top: 5px;
            }
            border-radius: 5px;
            &.selected {
                background-color: rgba(0,0,0,0.05);
            }
            padding: 5px 15px;
            cursor: pointer;
            &:hover{
                background-color: rgba(0,0,0,0.05);
            }
        }
    }
}