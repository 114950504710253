.sticky-top {
    position: sticky !important;
    top: 0 !important;
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
}

.sticky-left {
    position: -webkit-sticky;
    position: sticky !important;
    left: 0 !important;
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
}

.sticky-right {
    position: -webkit-sticky;
    position: sticky !important;
    right: 0 !important;
}

.sticky-bottom {
    position: -webkit-sticky;
    position: sticky !important;
    bottom: 0 !important;
}

.sticky-fit-width {
    position: -webkit-sticky;
    position: sticky !important;
    width: 100vw !important;
}