.user-popper{
    z-index: 1;
    .avatar-container{
        background-color: #fff;
        border-radius: 2px;
        width: 250px;
        box-shadow: 0 4px 12px 0 rgba(0,0,0,0.2);
        .popper-header{
            background-color: #fff;
            display: flex;
            align-items: center;
            width: 100%;
            padding: 15px;
            border-bottom: 1px solid #ddd;
            &::before{
                display: none;
            }
        }
        .popper-body{
            background-color: #fff;
            padding: 0;
            ul.popper-tools-list{
                padding: 8px 0;
                li.popper-tools-item{
                    padding: 10px 0;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    font-size: 14px;
                    &:hover{
                        background-color: rgba(0,0,0,0.05);
                    }
                    .popper-icon{
                        width: 50px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        > i{
                            color: #909090;
                        }
                    }
                    .popper-label{
                        flex: 1;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}