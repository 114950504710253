.ck-editor{
    width: 100%;
    position: relative;
    z-index: 0;
    #toolbar-container{
        .ck-toolbar{
            border-bottom: 1px solid #e3e3e3;
        }
        .ck-rounded-corners{
            border-radius: 5px 5px 0 0;
        }
    }
    .ck-editor__editable_inline{
        background-color: #fff;
        min-height: 300px;
        border: 1px solid #e3e3e3 !important;
        border-top: 0 !important;
        border-radius: 0 0 5px 5px !important;
        font-family: $Noto-Sans;
        &.ck-focused{
            box-shadow: none !important;
            outline: none !important;
        }
    }
}
