.switch {
    position: relative;
    display: inline-block;
    width: 52px !important;
    max-width: 52px !important;
    min-width: 52px !important;
    height: 26px;
    text-align: initial;
    // margin: 4px 0;
    input{
        opacity: 0;
        width: 0;
        height: 0;
        &:checked ~ .slider:before{
            background-color: #fff;
        }
    }
    .slider{
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $text-secondary;
        -webkit-transition: .4s;
        transition: .4s;
        &::before{
            position: absolute;
            content: "";
            height: 14px;
            width: 14px;
            top: 50%;
            left: 5px;
            transform: translateY(-50%);
            background-color: $text-third;
            -webkit-transition: .4s;
            transition: .4s;
        }
		&::after {
			content: "OFF";
			position: absolute;
			top: 50%;
            right: 8px;
            transform: translateY(-50%);
			font-size: 12px;
			color: #fff;
		}
        &.round {
			border-radius: 34px;
			&::before {
				border-radius: 50%;
				z-index: 1;
				// border: 1px solid #d1d1d1;
				// box-shadow: 0 2px 3px rgba(0,0,0,0.2);
			}
        }
    }
}
  
input:checked + .slider {
	// background-color: #4BD964;
	background-color: #2EC4B6;
}

input:checked + .slider:before {
	-webkit-transform: translateY(-50%) translateX(28px);
	-ms-transform: translateY(-50%) translateX(28px);
	transform: translateY(-50%) translateX(28px);
}

input:checked + .slider:after {
	content: "ON";
	position: absolute;
	left: 8px;
}