.cart-page{
    .discount-input{
        border: 1px solid rgba(0,0,0,0.1);
        padding: 5px 10px;
        &:read-only{
            background-color: transparent;
            border: 1px solid transparent;
            padding: 5px 0;
        }
    }
    textarea{
        border: 1px solid rgba(0,0,0,0.1);
        flex: 1;
        padding: 10px;
        border-radius: 3px;
        &::placeholder{
            color: #aaa;
        }
        &:read-only{
            background-color: #efefef;
            border: 1px solid #efefef;
        }
    }
    .cart-container{
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        padding: 0 30px;
        @media screen and (max-width: 991px){
            padding: 0 15px;
        }
        ul.cart-list{
            margin: 0 -15px;
            li.cart-item{
                display: flex;
                flex-direction: column;
                align-items: stretch;
                padding: 15px;
                border-bottom: 1px solid rgba(0,0,0,0.1);
                position: relative;
                .remove-box{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(255,255,255,0.6);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    z-index: 1;
                    > button{
                        padding: 8px 16px;
                        background-color: $main-color;
                        color: #fff;
                        border-radius: 50px;
                        border: 0;
                        box-shadow: 1px 2px 3px rgba(0,0,0,0.2);
                    }
                }
                .cart-image{
                    width: 100px;
                    height: 100px;
                    border-radius: 5px;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: cover;
                    background-image: url('../../images/web/no-product.png');
                    @media screen and (max-width: 575px){
                        width: 80px;
                        height: 80px;
                    }
                }
                .cart-info{
                    margin-left: 15px;
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    font-size: 14px;
                    @media screen and (max-width: 575px){
                        font-size: 12px;
                    }
                    .category{
                        font-size: 12px;
                        color: #969696;
                    }
                    .remove-btn{
                        border: 0;
                        background-color: transparent;
                        color: $main-color;
                        position: relative;
                        padding: 0;
                        line-height: 0;
                        &:hover{
                            &::after{
                                content: "";
                                position: absolute;
                                bottom: 0;
                                left: 0;
                                width: 100%;
                                height: 1px;
                                background-color: $main-color;
                            }
                        }
                    }
                }
                .quantity-container{
                    // text-align: right;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: 15px;
                    @media screen and (max-width: 575px){
                        flex-direction: row;
                    }
                }
                .quantity-box{
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    @media screen and (max-width: 575px){
                        font-size: 12px;
                    }
                    .quantity-btn{
                        background-color: transparent;
                        border: 1px solid rgba(0,0,0,0.15);
                        width: 32px;
                        min-width: 32px;
                        max-width: 32px;
                        height: 32px;
                        min-height: 32px;
                        max-height: 32px;
                        font-size: 18px;
                        line-height: 0;
                        padding: 0;
                        &:hover{
                            background-color: #f9f9f9;
                        }
                        &:disabled{
                            pointer-events: none;
                            color: #ccc;
                        }
                    }
                    .quantity-input{
                        border: 0;
                        border-radius: 0;
                        text-align: center;
                        width: 50px;
                        height: 32px;
                        font-size: 14px;
                        border-top: 1px solid rgba(0,0,0,0.15);
                        border-bottom: 1px solid rgba(0,0,0,0.15);
                        &:read-only{
                            color: #ccc;
                        }
                    }
                }
            }
        }
        .submit-container{
            text-align: right;
            padding: 15px 0;
        }
    }
}