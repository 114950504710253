.user-wrapper{
    padding-top: 60px;
    height: 100%;
    display: flex;
    main {
        margin-left: 0;
        flex: 1;
        overflow: overlay;
        -webkit-overflow-scrolling: touch;
        transition: margin-left 0.3s ease-in-out;
        // padding-bottom: 70px;
        width: 100%;
        @media screen and (min-width: 992px){
            &.active{
                margin-left: 250px;
                .sticky-fit-width {
                    width: calc(100vw - 250px) !important;
                    // transition: all 0.3s ease-in-out;
                }
            }
        }
        .sticky-fit-width {
            // transition: all 0.3s ease-in-out;
        }
        .wrapper-container{
            display: flex;
            flex-direction: column;
            min-height: 100%;
            &.fit-content{
                width: fit-content;
                min-width: 100%;
            }
            .wrapper-header{
                padding: 0 30px;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                align-items: center;
                @media screen and (max-width: 992px){
                    padding: 0 15px;
                }
                .wrapper-title{
                    margin-top: 15px;
                    margin-right: 15px;
                    font-size: 25px;
                    font-weight: bold;
                    color: #0d0d0d;
                }
            }
            .wrapper-content{
                padding-top: 30px;
                padding-bottom: 70px;
            }
        }
    }
}