.stat-container{
    padding: 0 30px;
    @media screen and (max-width: 992px){
        padding: 0 15px;
    }
    & + .stat-container{
        margin-top: 20px;
    }
}

ul.stat-list{
    align-items: stretch;
    .stat-item{
        padding: 0 7.5px;
        margin-bottom: 15px;
        .stat-box{
            background: #fff;
            border-radius: 5px;
            box-shadow: 0 0 1px rgba(0,0,0,0.13), 0 1px 3px rgba(0,0,0,0.2);
            height: 100%;
            position: relative;
            display: flex;
            flex-direction: column;
            &.theme-main{
                background: $main-color;
                // background: $main-color linear-gradient(180deg,$main-color,$main-color);
                color: #fff;
            }
            &.theme-primary{
                background: #007bff linear-gradient(180deg,#268fff,#007bff);
                color: #fff;
            }
            &.theme-secondary{
                background: #6c757d linear-gradient(180deg,#828a91,#6c757d);
                color: #fff;
            }
            &.theme-info{
                background: #17a2b8 linear-gradient(180deg,#3ab0c3,#17a2b8);
                color: #fff;
            }
            &.theme-success{
                background: #28a745 linear-gradient(180deg,#48b461,#28a745);
                color: #fff;
            }
            &.theme-warning{
                background: #ffc107 linear-gradient(180deg,#ffca2c,#ffc107);
                color: #1f2d3d;
            }
            &.theme-danger{
                background: #dc3545 linear-gradient(180deg,#e15361,#dc3545);
                color: #fff;
            }
            .info{
                padding: 10px 15px;
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;
                position: relative;
                @media screen and (max-width: 767px){
                    text-align: center;
                }
                .content{
                    font-size: 36px;
                    // font-weight: bold;
                    @media screen and (max-width: 767px){
                        font-size: 24px;
                    }
                    @media screen and (max-width: 415px){
                        font-size: 16px;
                    }
                }
                label{
                    font-weight: normal;
                    @media screen and (max-width: 767px){
                        font-size: 14px;
                    }
                }
                i.stat-icon{
                    position: absolute;
                    top: calc(50%);
                    right: 15px;
                    transform: translateY(-50%);
                    font-size: 50px;
                    color: rgba(0,0,0,0.075);
                    @media screen and (max-width: 767px){
                        display: none;
                    }
                }
            }
            a.stat-link{
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: rgba(0,0,0,0.075);
                height: 30px;
                color: inherit;
                font-size: 14px;
            }
        }
    }
}