.menu-sidebar{
    position: absolute;
    width: 250px;
    height: calc(100% - 60px);
    overflow-y: auto;
    background-color: #fff;
    transition: transform 0.3s ease-in-out;
    transform: translateX(-100%);
    z-index: 995;
    border-right: 1px solid #eee;
    &.active{
        transform: translateX(0);
    }
    &::-webkit-scrollbar {
        display: none;
    }
    @media screen and (max-width: 415px) {
        width: 100%;
        transform: translateX(-100%);
    }
    nav{
        padding: 10px 0;
        ul.sidebar-list{
            display: flex;
            flex-direction: column;
            li.sidebar-item{
                &.menu-open{
                    .sidebar-label{
                        &.has-children{
                            &::after{
                                transform: rotate(180deg);
                            }
                        }
                    }
                }
                .sidebar-label{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    color: #555;
                    padding: 12px 15px;
                    cursor: pointer;
                    &.active{
                        background-color: rgba(0,0,0,0.05);
                        box-shadow: none;
                        font-weight: bold;
                        font-family: 微軟正黑體;
                        @media screen and (max-width: 415px){
                            background-color: transparent;
                        }
                    }
                    &.has-children{
                        &::after{
                            content: "\f078";
                            font-family: "Font Awesome 5 Pro";
                            font-size: 12px;
                            font-weight: normal;
                            transition: all 0.15s ease-in-out;
                        }
                    }
                    &:hover{
                        background-color: rgba(0,0,0,0.05);
                    }
                    .sidebar-icon{
                        width: 30px;
                        display: flex;
                        justify-content: center;
                    }
                    .label{
                        display: flex;
                        align-items: center;
                        padding: 0 5px;
                        font-size: 14px;
                        flex: 1;
                    }
                }
                ul.expand-list{
                    padding: 5px 0;
                    li.expand-item{
                        
                    }
                }
            }
        }
    }
}

a.expand-link{
    width: 100%;
    padding: 12px 15px;
    position: relative;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    &:hover{
        background-color: #f9f9f9;
        i{
            color: #555;
        }
    }
    &.active{
        background-color: #f9f9f9;
        // color: #cc9800;
        color: #e95098;
        i{
            // color: #cc9800;
            color: #e95098;
            font-weight: bold;
        }
        .label{
            // color: #cc9800;
            color: #e95098;
        }
        &::before{
            display: block;
        }
    }
    &:before{
        display: none;
        content: '';
        position: absolute;
        width: 5px;
        height: 100%;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        // background-color: #cc9800;
        background-color: #e95098;
    }
    i{
        width: 30px;
        display: flex;
        justify-content: center;
        font-size: 18px;
        font-weight: 100;
        color: #555;
    }
    .label{
        padding: 0 10px;
        font-weight: normal;
        color: #555;
    }
}