.common-btn{
    border: 0;
    border-radius: 2px;
    background-color: transparent;
    padding: 6px 12px;
    transition: all 0.1s ease-in-out;
    font-size: 0.85rem;
    -webkit-appearance: none;
    appearance: none;
    display: inline-block;
    &.btn-lg{
        padding: 8px 16px;
        font-size: 15px;
    }
    &.table-btn{
        &.red{
            color: #f4433664;
            background-color: #f443361c;
        }
        &.black{
            color: #aaa;
            background-color: rgba(0,0,0,0.05) !important;
        }
        &.green{
            background-color: #43a0481d;
        }
        &.blue{
            background-color: #4080ce26;
        }
    }
    &.red{
        color: #F44336;
        &:hover{
            background-color: #F44336;
            color: #fff;
            box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.2), 0 1px 5px 0 rgba(0,0,0,0.12);
        }
    }
    &.black{
        color: #666;
        &:hover{
            background-color: rgba(0,0,0,0.05);
            color: $text-dark;
            box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.2), 0 1px 5px 0 rgba(0,0,0,0.12);
        }
    }
    &.green{
        color: #43A047;
        &:hover{
            background-color:#43A047;
            color: #fff;
            box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.2), 0 1px 5px 0 rgba(0,0,0,0.12);
        }
    }
    &.blue{
        color: #4081ce;
        &:hover{
            background-color:#4081ce;
            color: #fff;
            box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.2), 0 1px 5px 0 rgba(0,0,0,0.12);
        }
    }
}