/*!
 * Global styling for this template
 */

 *{
    box-sizing: border-box;
    &:focus-visible{
        outline: none;
    }
}

.bg-main{
    background-color: $main-color;
}
.text-main{
    color: $main-color;
}

.modal-open{
    overflow: hidden;
}

.font-family-number{
    font-family: $fontNumber;
}

ul{
    margin: 0;
    padding: 0;
    li{
        list-style: none;
    }
}

label{
    margin-bottom: 0;
}

@for $i from 0 through 8 {
    .pd-#{$i}{
        padding: $i*5px !important;
    }
    .pdx-#{$i}{
        padding-right: $i*5px !important;
        padding-left: $i*5px !important;
    }
    .pdy-#{$i}{
        padding-top: $i*5px !important;
        padding-bottom: $i*5px !important;
    }
    .pdt-#{$i}{
        padding-top: $i*5px !important;
    }
    .pdr-#{$i}{
        padding-right: $i*5px !important;
    }
    .pdb-#{$i}{
        padding-bottom: $i*5px !important;
    }
    .pdl-#{$i}{
        padding-left: $i*5px !important;
    }
    .mg-#{$i}{
        margin: $i*5px !important;
    }
    .mgx-#{$i}{
        margin-right: $i*5px !important;
        margin-left: $i*5px !important;
    }
    .mgy-#{$i}{
        margin-top: $i*5px !important;
        margin-bottom: $i*5px !important;
    }
    .mgt-#{$i}{
        margin-top: $i*5px !important;
    }
    .mgr-#{$i}{
        margin-right: $i*5px !important;
    }
    .mgb-#{$i}{
        margin-bottom: $i*5px !important;
    }
    .mgl-#{$i}{
        margin-left: $i*5px !important;
    }
}

html, body {
    height: 100% !important;
}

body {
    font-family: 微軟正黑體, sans-serif;
    margin: 0;
    padding: 0;
    width: 100%;
    min-height: 100% !important;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    color: #555;
    // background-color: #f9f9f9;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}
::-webkit-scrollbar-track {
    margin: 5px 0;
}
::-webkit-scrollbar-thumb {
    -webkit-border-radius: 100px;
    border-radius: 100px;
    background: rgb(159,159,162);
}


.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $Default-font;
}

@for $i from 12 through 100 {
    .font-size-#{$i}{
        font-size: $i*1px !important;
    }
}

@for $i from 1 through 10 {
    .text-break-#{$i}{
        overflow: hidden;
        text-overflow: ellipsis;
        @if $i == 1{
            white-space: nowrap;
        } @else {
            display: -webkit-box;
            -webkit-line-clamp: $i;
            -webkit-box-orient: vertical;
        }
    }
}

a {
    &:link,
    &:visited,
    &:hover,
    &:active {
        text-decoration: none;
    }
}

input[type=text],
input[type=password],
input[type=number],
input[type=tel],
input[type=color],
textarea{
    -webkit-appearance: none;
	appearance: none;
}

select,
textarea{
    appearance: none;
    outline: none;
}

button {
    -webkit-appearance: none;
    appearance: none;
    &:focus{
        outline: none;
    }
}

input:disabled,
textarea:diabled {
    -webkit-text-fill-color: inherit;
    -webkit-opacity: 1;
    color: inherit;
}

// #root {
//     height: 100%;
// }

.no-data {
    width: 100%;
    padding: 30px 0;
    text-align: center;
    color: #555;
    font-weight: bold;
    font-size: 0.9rem;
    &::before {
        display: block;
		content: "";
		height: 120px;
		text-shadow: 2px 2px 4px rgba(0,0,0,0.7);
		background-image: url('../images/web/nodata.png');
		background-position: center;
		background-size: 100px 100px;
		background-repeat: no-repeat;
    }
}

#app {
    margin: 0 auto;
    position: relative;
    min-height: 100% !important;
    height: 100%;
    width: 100%;
}

.avatar-upload{
    cursor: pointer;
    &:before{
        content: '';
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.4);
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        border-radius: 50%;
    }
    &:after{
        content: '\f083';
        font-family: 'Font AweSome 5 Pro';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        color: white;
        font-size: 26px;
        opacity: 0;
    }
    &:hover:after, &:hover:before{
        opacity: 1;
    }
    #upload-avatar-input{
        position: absolute;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        opacity: 0;
        cursor: pointer;
    }
}

.avatar-img {
    width: 100px;
    height: 100px;
    position: relative;
    overflow: hidden;
    margin: auto;
    border: 1px solid #ebebeb;
    border-radius: 50%;
    img{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

h5{
    font-weight: bold;
    font-size: 1rem;
    margin-bottom: 15px;
}

input[type="number"]::-webkit-outer-spin-button, 
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}

.tools-btn{
    position: fixed;
    right: 16px;
    bottom: 16px;
    z-index: 900 !important;
    > button{
        background-color: #1976d2;
        color: #fff;
        &:hover{
            background-color: #166ec5;
        }
    }
    .MuiSpeedDialAction-tooltipPlacementLeft{
        .MuiSpeedDialAction-staticTooltipLabel{
            background-color: #555;
            color: #fff;
        }
    }
}

.ReactCollapse--collapse {
    transition: height 0.3s ease-in-out;
}