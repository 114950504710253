.dropdown, .dropup, .dropright, .dropleft{
    .custom-dropdown-menu{
        padding: 8px 0;
        .dropdown-item{
            padding: 10px 15px 10px 0;
            display: flex;
            align-items: center;
            font-size: 14px;
            &:hover, &:active{
                background-color: rgba(0,0,0,0.05);
                color: unset;
            }
            i {
                width: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}