.logo-image{
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    display: inline-block;
    &::after{
        content: "";
        display: block;
        padding-top: 18.1972789%;
    }
    &.logo-pink{
        background-image: url('../../images/logo/logo-pink.png');
    }
    &.logo-white{
        background-image: url('../../images/logo/logo-white.png');
    }
}