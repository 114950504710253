/*!
 * Home styling for this template
 */

.login-page {
	background-image: url('../images/web/login_bg.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	// background-color: #e95098;
	// background-color: #1976d2;
	height: 100%;
	display: flex;
	overflow-y: scroll;
	&::-webkit-scrollbar{
		display: none;
	}
	.logo-container{
		margin: auto;
		text-align: center;
		padding: 50px 0 70px 0;
		width: 100%;
		.login-box{
			max-width: 415px;
			width: calc(100% - 30px);
			padding: 15px;
			border-radius: 10px;
			background-color: #fff;
			margin: 0 auto;
			.MuiOutlinedInput-root{
				.MuiOutlinedInput-notchedOutline{
					border-color: #d9d9d9;
				}
				&:focus-within{
					.MuiOutlinedInput-notchedOutline{
						border-color: #3f51b5 !important;
					}
				}
				&:hover{
					.MuiOutlinedInput-notchedOutline{
						border-color: #d9d9d9;
					}
				}
			}
			.input-text{
				position: absolute;
				right: 10px;
				top: 50%;
				transform: translateY(-50%);	
			}
			.login-btn{
				width: 100%;
				padding: 10px 0;
				border: 0;
				border-radius: 5px;
				transition: all 0.15s ease-in-out;
				font-weight: bold;
				font-family: 微軟正黑體;
				background-color: #e95098;
				color: #fff;
				&:disabled{
					background-color: #cdcdcd;
					color: #7a7a7a;
					pointer-events: none;
				}
			}
		}
	}
}