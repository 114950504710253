header.header{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background-color: rgba(255,255,255,1);
    z-index: 999;
    // border-bottom: 1px solid #ebebeb;
    &::after{
        content: "";
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: #ebebeb;
    }
    nav{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        
        .nav-title{
            font-size: 20px;
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}

.admin-menu{
    height: 100%;
    max-width: 250px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    color: #888;
    cursor: pointer;
    &:hover{
        color: #555;
        background-color: rgba(0,0,0,0.05);
    }
    > .shop-logo{
        min-width: 36px;
        max-width: 36px;
        width: 36px;
        min-height: 36px;
        max-height: 36px;
        height: 36px;
        border: 2px solid #fff;
        box-shadow: 0 0 3px rgba(0,0,0,0.3);
        border-radius: 3px;
        background-color: #fff;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin-right: 10px;
    }
    > .name{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        @media screen and (max-width: 415px){
            display: none;
        }
    }
}