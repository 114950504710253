.user-team-page{
    .tooltips-container{
        background-color: #fff;
        display: flex;
        flex-direction: column;
        .tooltips-title{
            background-color: #1976d2;
            color: #fff;
            text-align: center;
            padding: 10px 0;
        }
        .tooltips-box{
            display: flex;
            align-items: center;
            .tooltips-icon{
                width: 30px;
                text-align: center;
                i{
                    color: #F6C431;
                }
            }
            > span{
                white-space: nowrap;
            }
        }
    }
}

ul.team-list{
    > li.team-item {
        padding: 15px;
        background-color: #fff;
        border-radius: 5px;
        box-shadow: 1px 1px 3px rgba(0,0,0,0.1);
        margin-top: 15px;
        overflow-x: auto;
    }
    li.team-item{
        padding-top: 15px;
        .item-content{
            position: relative;
            &.has-list {
                cursor: pointer;
                // &::after {
                //     content: "\f078";
                //     font-family: "font awesome 5 Pro";
                //     font-weight: 200;
                //     font-size: 16px;
                //     position: absolute;
                //     top: 0;
                //     right: 0;
                //     transition: all 0.3s ease-in-out;
                //     transform-origin: 50% 50%;
                // }
                // &.open {
                //     &::after {
                //         transform: rotate(180deg);
                //     }
                // }
                .item-title{
                    &::after {
                        content: "\f078";
                        font-family: "font awesome 5 Pro";
                        font-weight: 200;
                        font-size: 16px;
                        // position: absolute;
                        // top: 0;
                        // right: 0;
                        transition: all 0.3s ease-in-out;
                        transform-origin: 50% 50%;
                        margin-left: 10px;
                    }
                    &.open {
                        &::after {
                            transform: rotate(180deg);
                        }
                    }
                }
            }
            .item-title {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .title{
                    // overflow: hidden;
                    // text-overflow : ellipsis;
                    white-space: nowrap;
                    font-weight: bold;
                    font-size: 16px;
                }
            }
            i {
                color: #F6C431;
                margin-right: 10px;
                font-size: 15px;
                // display: inline-block;
                // width: 20px;
                // height: 20px;
                // text-align: center;
                // margin-right: 10px;
                // font-size: 24px;
                // background-position: center;
                // background-repeat: no-repeat;
                // background-size: contain;
                // &.level-1 {
                //     background-image: url('../../images/web/user-06.png');
                // }
                // &.level-2 {
                //     width: 36px;
                //     background-image: url('../../images/web/user-07.png');
                // }
                // &.level-3 {
                //     width: 29px;
                //     background-image: url('../../images/web/user-08.png');
                // }
                // &.level-4 {
                //     width: 31px;
                //     background-image: url('../../images/web/user-09.png');
                // }
                // &.level-5 {
                //     width: 31px;
                //     background-image: url('../../images/web/user-10.png');
                // }
            }
        }
        ul.team-expand{
            > li.team-item{
                margin-left: 30px;
                > .item-content{
                    &::before{
                        content: "";
                        display: inline-block;
                        width: 20px;
                        height: 20px;
                        border-bottom: 1px solid #555;
                        border-left: 1px solid #555;
                        position: absolute;
                        top: -5px;
                        left: -25px;
                    }
                }
            }
        }
    }
}