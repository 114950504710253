.modal-box{
    &.scroll-paper{
        .light-box, .confirm-box{
            max-height: 80%;
            display: flex;
            flex-direction: column;
        }
    }
    &.scroll-body{
        overflow-y: auto;
        display: unset;
        .light-box, .confirm-box{
            display: inline-block;
            vertical-align: middle;
        }
        &::after{
            content: "";
            display: inline-block;
            vertical-align: middle;
            height: 100%;
            width: 0px;
        }
    }
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    visibility: hidden;
    background-color: transparent;
    transition: all 0.3s ease-in-out;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    &.active{
        visibility: visible;
        background-color: rgba(0,0,0,0.55);
    }
}