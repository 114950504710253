.modal-box{
    .light-box{
        text-align: left;
        width: 90%;
        max-width: 415px;
        background-color: transparent;
        transition: all 0.2s ease-in-out;
        margin: 30px auto;
        position: relative;
        > div{
            &:first-of-type{
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
            }
            &:last-of-type{
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
            }
            & + div{
                margin-top: -1px;
            }
        }
        .light-box-body{
            overflow-y: scroll;
            &::-webkit-scrollbar {
                display: none;
            }
        }
        .light-box-footer{
            padding: 15px;
            text-align: right;
            button{
                -webkit-appearance: none;
                appearance: none;
                padding: 5px 15px;
                border-radius: 3px;
                border: 0;
                transition: all 0.15s ease-in-out;
                color: $text-secondary;
                &:hover{
                    color: $text-dark;
                }
                &:focus{
                    outline: none;
                }
            }
            .cancel-btn{
                background-color: transparent;
            }
            .confirm-btn{
                background-color: #eee;
            }
        }
        .light-box-close{
            width: 30px;
            height: 30px;
            background-color: rgba(0,0,0,0.2);
            position: absolute;
            top: 10px;
            right: 10px;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            z-index: 100;
            backdrop-filter: blur(5px);
            &:hover{
                background-color: rgba(0,0,0,0.3);
            }
            &::before, &::after{
                content: "";
                position: absolute;
                width: 20px;
                height: 1.5px;
                background-color: #fff;
            }
            &::before{
                transform: rotate(45deg);
            }
            &::after{
                transform: rotate(135deg);
            }
        }
    }
}

.lightbox-transition-enter{
    visibility: hidden !important;
    background-color: rgba(0,0,0,0) !important;
    .light-box{
        transform: scale(0.9) !important;
        opacity: 0 !important;
    }
}
.lightbox-transition-enter-active{
    visibility: visible !important;
    background-color: rgba(0,0,0,0.55) !important;
    transition: all 300ms ease-in-out !important;
    .light-box{
        transform: scale(1) !important;
        opacity: 1 !important;
        transition: all 200ms ease-in-out !important;
    }
}
.lightbox-transition-enter-done{
    visibility: visible !important;
    background-color: rgba(0,0,0,0.55) !important;
    .light-box{
        transform: scale(1) !important;
        opacity: 1 !important;
    }
}

.lightbox-transition-exit{
    visibility: visible !important;
    background-color: rgba(0,0,0,0.55) !important;
    .light-box{
        transform: scale(1) !important;
        opacity: 1 !important;
    }
}
.lightbox-transition-exit-active{
    visibility: hidden !important;
    background-color: rgba(0,0,0,0) !important;
    transition: all 300ms ease-in-out !important;
    .light-box{
        transform: scale(0.9) !important;
        opacity: 0 !important;
        transition: all 200ms ease-in-out !important;
    }
}
.lightbox-transition-exit-done{
    visibility: hidden !important;
    background-color: rgba(0,0,0,0) !important;
    .light-box{
        transform: scale(0.9) !important;
        opacity: 0 !important;
    }
}